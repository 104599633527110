// extracted by mini-css-extract-plugin
export var bodyContainer = "Category-module--bodyContainer--po-5i";
export var card = "Category-module--card--cQz3C";
export var ctas = "#226355";
export var description = "Category-module--description--p62Zd";
export var grayBackground = "#f6f8fb";
export var imageContainer = "Category-module--imageContainer--Qtm5Y";
export var imgContainer = "Category-module--imgContainer--cqpQz";
export var text = "Category-module--text--J8t7o";
export var textContainer = "Category-module--textContainer--8Jl2W";
export var title = "Category-module--title--UV6GG";