import React, { FC } from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Card } from "react-bootstrap"
import { SanityCollection } from "@utils/globalTypes"
import TextWithArrow from "@components/common/TextWithArrow"
import * as s from "./Category.module.scss"

type Props = {
  category: SanityCollection
}
const Category: FC<Props> = ({ category }) => {
  const { title, handle, image } = category

  return (
    <Card as={Link} to={`/products/${handle}`} className={s.card}>
      <Card.Img className={s.imageContainer} as="div" variant="top">
        {image && (
          <GatsbyImage
            image={image?.localFile?.childImageSharp?.gatsbyImageData}
            style={{ maxHeight: "100%" }}
            alt="confirm bio"
            imgStyle={{ objectFit: "contain" }}
          />
        )}
      </Card.Img>
      <Card.Body className={s.bodyContainer}>
        <Card.Title className={s.title}>{title}</Card.Title>
        <Card.Text className={s.description} />
        <TextWithArrow
          style={{
            height: "unset",
            fontWeight: "bold",
            color: "#226355",
            bottom: "1rem",
          }}
          text="View Category"
        />
      </Card.Body>
    </Card>
  )
}

export default Category
