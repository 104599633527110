import { Col, Container, Row } from "react-bootstrap"
import { SanityCollection } from "@utils/globalTypes"
import React, { FC } from "react"

import FAQQuestion from "@components/common/FAQ"
import * as s from "./FAQQuestionSection.module.scss"

type Props = {
  sanityCollection?: SanityCollection
  title: string
}

const FAQQuestionSection: FC<Props> = ({ title, sanityCollection }) => {
  const faqs = sanityCollection?.faqs

  if (!faqs || !faqs.length) {
    return <></>
  }

  return (
    <Container id="faqs" className={s.container}>
      <Row>
        <Col xs={12}>
          <h2 className={s.title}>{title} FAQs</h2>
        </Col>
        <Col xs={12}>
          <Container>
            <Row>
              {faqs.map((question, index) => (
                <Col
                  key={index}
                  xs={12}
                  md={6}
                  lg={6}
                  className={s.questionCol}
                >
                  <FAQQuestion
                    question={question.question}
                    _rawAnswer={question._rawAnswer}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default FAQQuestionSection
