import Banner from "@components/common/Banner"
import React from "react"
import * as s from "./BannerContainer.module.scss"

const BannerContainer: React.FC = () => {
  return (
    <div className={s.container}>
      <Banner />
    </div>
  )
}

export default BannerContainer
