import {
  Collection,
  SanityCollection,
  ShopifyAdminAPI,
} from "@utils/globalTypes"
import React, { FC } from "react"

import BannerContainer from "./BannerContainer"
import CollectionDescription from "./CollectionDescription"
import FAQQuestionSection from "./FAQQuestionSection"
import GuideResourcesContainer from "./GuideResourcesContainer"
import OtherCategories from "./OtherCategories"
import PopularCategories from "./PopularCategories"
import ProductCardsContainer from "./ProductCardsContainer"
import SEO from "@components/common/SEO"
import { graphql } from "gatsby"
import { Container } from "react-bootstrap"
import { BaseBlockContent } from "@utils/richText"
import * as s from "./Collection.module.scss"

type Props = {
  data: {
    shopifyCollection: Collection
    shopifyAdminAPI: ShopifyAdminAPI
    sanityCollection: SanityCollection
    categoriesToUse: {
      edges: { node: { shopifyCollection: Collection } }[]
    }
  }
  location: Location
}

const CollectionPage: FC<Props> = ({
  data: {
    shopifyCollection: collection,
    shopifyAdminAPI,
    sanityCollection,
    categoriesToUse,
  },
  location,
}) => {
  const metadata = {
    location: location.href,
    title: collection.title,
    description: collection.description,
  }
  return (
    <>
      <SEO {...metadata} />
      <CollectionDescription collection={collection} />
      <OtherCategories otherCategories={categoriesToUse} />
      <ProductCardsContainer
        collection={collection}
        metafields={shopifyAdminAPI.collectionByHandle.metafields}
      />
      <BannerContainer />
      <PopularCategories popularCategories={categoriesToUse} />
      <GuideResourcesContainer sanityCollection={sanityCollection} />
      <Container>
        <BaseBlockContent
          className={s.textFieldContainer}
          blocks={sanityCollection?.textField}
        />
      </Container>
      <FAQQuestionSection
        title={collection.title}
        sanityCollection={sanityCollection}
      />
    </>
  )
}

export default CollectionPage

export const pageQuery = graphql`
  query ($handle: String!) {
    shopifyCollection(handle: { eq: $handle }) {
      id
      title
      description
      handle
      descriptionHtml
      products {
        id
        title
        description
        handle
        images {
          id
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      image {
        id
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
    shopifyAdminAPI {
      collectionByHandle(handle: $handle) {
        metafields(first: 10) {
          edges {
            node {
              key
              value
            }
          }
        }
      }
    }
    categoriesToUse: allShopifyCollection(
      filter: {
        handle: {
          in: [
            "etg"
            "synthetic-drug-tests"
            "clia-waived-drug-tests"
            "laboratory-services"
            "best-selling-drug-tests"
            "saliva-drug-tests"
            "urine-drug-test-cups"
            "urine-drug-test-dip-cards"
            "most-popular-drug-tests"
          ]
        }
      }
    ) {
      edges {
        node {
          title
          shopifyId
          id
          handle
          image {
            id
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
    sanityCollection: sanityCollection(handle: { current: { eq: $handle } }) {
      textField: _rawTextField(resolveReferences: { maxDepth: 10 })
      faqs {
        question
        _rawAnswer
      }
      resources {
        _type
        title
        slug {
          current
        }
        category {
          name
          slug {
            current
          }
        }
      }
    }
  }
`
