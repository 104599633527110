import { Col, Container, Row } from "react-bootstrap"
import {
  Collection,
  collection_keys,
  SanityCollection,
} from "@utils/globalTypes"
import Category from "@components/collections/Category"
import React, { FC } from "react"
import classnames from "classnames"
import * as s from "./PopularCategories.module.scss"

type Props = {
  popularCategories: {
    edges: { node: { shopifyCollection: Collection } }[]
  }
}

const PopularCategories: FC<Props> = ({ popularCategories }) => {
  const { categories } = getPopularCategories(popularCategories)
  const categoryContainerClasses = classnames("mb-4")
  return (
    <Container className={s.categoriesContainer}>
      <h2 className={s.title}>Popular Categories</h2>
      <Row className="justify-content-center">
        {categories &&
          categories.map((category, index) => (
            <Col
              className={categoryContainerClasses}
              key={index}
              xs={12}
              sm={6}
              lg={3}
            >
              <Category category={category} />
            </Col>
          ))}
      </Row>
    </Container>
  )
}

const categoriesFields = [
  collection_keys.etg,
  collection_keys.synthetic,
  collection_keys.clia_waived,
  collection_keys.lab_testing,
]

const getPopularCategories = (
  popularCategories: any
): { categories: SanityCollection[] } => {
  const cats = popularCategories.edges.filter((meta) =>
    categoriesFields.includes(meta.node.handle)
  )
  const categories = cats.map((cat) => cat.node)
  return { categories }
}

export default PopularCategories
