import { Col, Container, Row } from "react-bootstrap"
import React from "react"
import ResourceElement from "@components/common/ResourceElement"
import * as s from "./GuideResourcesContainer.module.scss"
import { SanityCollection } from "@utils/globalTypes"

type Props = {
  sanityCollection?: SanityCollection
}

const GuideResourcesContainer: React.FC<Props> = ({ sanityCollection }) => {
  if (
    !sanityCollection ||
    !sanityCollection.resources ||
    !sanityCollection.resources.length
  )
    return <></>
  const { resources } = sanityCollection

  return (
    <div className={s.container}>
      <Container className={s.sectionContainer}>
        <Row className="justify-content-center">
          <Col xs={12}>
            <h2 className={s.title}>Guides and Resources</h2>
          </Col>
          {resources.map((res, index) => (
            <Col className="mb-4" lg={3} md={4} xs={12} key={index}>
              <ResourceElement resource={res} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default GuideResourcesContainer
