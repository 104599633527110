import { Col, Container, Row } from "react-bootstrap"
import React, { FC } from "react"

import { Collection } from "@utils/globalTypes"
import { ImageMockup } from "@components/mockups/mockups"
import { GatsbyImage } from "gatsby-plugin-image"
import classnames from "classnames"
import * as s from "./CollectionDescription.module.scss"

type Props = {
  collection: Collection
}

const CollectionDescription: FC<Props> = ({ collection }) => {
  const { title, image, description } = collection
  const imgContainerClasses = classnames("pl-0", "pr-0", s.imgContainer)
  const imgContainer = image ? (
    <GatsbyImage
      imgStyle={{ objectFit: "contain" }}
      alt=""
      image={image.localFile.childImageSharp?.gatsbyImageData}
    ></GatsbyImage>
  ) : (
    <ImageMockup className={s.image} />
  )

  return (
    <div className={s.container}>
      <Container style={{ maxWidth: 1080 }}>
        <Row>
          <Col className={s.titleContainer} xs={12}>
            <h1 className={s.title}>{title}</h1>
          </Col>
        </Row>
        <Row className={s.descContainer}>
          <Col md={6} xs={12} className={imgContainerClasses}>
            {imgContainer}
          </Col>
          <Col md={6} xs={12} className={s.description}>
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CollectionDescription
