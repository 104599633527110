import { Col, Container, Row } from "react-bootstrap"
import FullyRoundedDarkButton from "@components/common/Buttons/FullyRoundedDarkButton"
import { Link } from "gatsby"
import React, { FC } from "react"
import classnames from "classnames"
import { Collection, collection_keys } from "@utils/globalTypes"
import * as s from "./OtherCategories.module.scss"

type Props = {
  otherCategories: {
    edges: { node: { shopifyCollection: Collection } }[]
  }
}

const OtherCategories: FC<Props> = ({ otherCategories }) => {
  const { categories } = getOtherGategories(otherCategories)
  const collectionItemClasses = classnames(
    "justify-content-center",
    s.itemsContainer
  )

  const containerClassNames = classnames("mb-3")
  return (
    <Container className={collectionItemClasses}>
      <Row className={s.otherCategoriesContainer}>
        {categories &&
          categories.map((category, index) => (
            <Col
              lg={3}
              sm={6}
              xs={12}
              key={index}
              className={containerClassNames}
            >
              <Link to={`/products/${category.node.handle}`} className={s.link}>
                <FullyRoundedDarkButton style={{ width: "100%" }}>
                  <p className={s.buttonText}>{category.node.title}</p>
                </FullyRoundedDarkButton>
              </Link>
            </Col>
          ))}
      </Row>
    </Container>
  )
}

const categoriesFields = [
  collection_keys.urine_drug_test_cup,
  collection_keys.urine_drug_test_dip_card,
  collection_keys.saliva_drug_test,
  collection_keys.most_popular_drug_tests,
]

const getOtherGategories = (otherCategories: any) => {
  const unorderedCategories = otherCategories.edges.filter((meta) =>
    categoriesFields.includes(meta.node.handle)
  )
  const categories = []

  if (unorderedCategories)
    unorderedCategories.forEach((item) => {
      switch (item.node.handle) {
        case collection_keys.urine_drug_test_cup:
          categories[0] = item
          break
        case collection_keys.urine_drug_test_dip_card:
          categories[1] = item
          break
        case collection_keys.saliva_drug_test:
          categories[2] = item
          break
        case collection_keys.most_popular_drug_tests:
          categories[3] = item
          break
        default:
          categories.push(item)
          break
      }
    })

  return { categories }
}

export default OtherCategories
